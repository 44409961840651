import React , {useState} from "react";
import HelpCard from "../components/HelpCard";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
// @ts-ignore
import sessionsIcon from "../assets/img/outcome/outcome5.png";
// @ts-ignore
import inprogressIcon from "../assets/img/outcome/outcome6.png";
// @ts-ignore
import satisfactionIcon from "../assets/img/outcome/outcome3.png";
// @ts-ignore
import growthIcon from "../assets/img/outcome/outcome7.png";
// @ts-ignore
import progressIcon from "../assets/img/outcome/outcome8.png";
// @ts-ignore
import roiIcon from "../assets/img/outcome/outcome9.png";
// @ts-ignore
import nutshellImg from "../assets/img/nutshell.png";

const Outcome = () => {
  const [hasCounted, setHasCounted] = useState(false);

  const handleVisibilityChange = (isVisible:boolean) => {
    if (isVisible && !hasCounted) {
      setHasCounted(true);
    }
  };

  return (
    <div className="section bg-warm max-2xl:px-12">
      <h2 className="text-center mb-7">Coachello in a nutshell</h2>
      <div className="grid max-md:grid-cols-1 md:grid-cols-3 gap-2 ">
        <div className="flex flex-col gap-5 md:pt-8 lg:pt-24 2xl:pt-32">
          <HelpCard
            value={
              <CountUp suffix="+" end={500} redraw={true} separator=",">
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={(isVisible)=>handleVisibilityChange(isVisible)} delayedCall>
                    <span ref={countUpRef} />
                  </VisibilitySensor>
                )}
              </CountUp>
            }
            header="Coaches"
            des="Amazing coaches, ICF PCC+"
            // icon={sessionsIcon}
          />

          <HelpCard
            // valueSuffix="/5"
            value={
              <CountUp end={5} redraw={true}>
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={(isVisible)=>handleVisibilityChange(isVisible)} delayedCall>
                    <span ref={countUpRef} />
                  </VisibilitySensor>
                )}
              </CountUp>
            }
            header="External audits annually "
            des="Ensuring banking grade security with ISO 27001 & Microsoft 365 certification"
            // icon={satisfactionIcon}
          />
          <HelpCard
            value={
              <CountUp suffix="%" end={85} redraw={true}>
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={(isVisible)=>handleVisibilityChange(isVisible)} delayedCall>
                    <span ref={countUpRef} />
                  </VisibilitySensor>
                )}
              </CountUp>
            }
            header="Progress on coaching objectives"
            des="Based upon 3000+ coaching sessions. Other data points available from dashboard.  "
            // icon={progressIcon}
          />
        </div>
        <div className="w-full hidden md:block">
          <img className="w-full" src={nutshellImg} />
        </div>
        <div className="flex flex-col gap-5  md:pt-24 lg:pt-44 2xl:pt-72">
          <HelpCard
            value={
              <CountUp suffix="%" start={1} end={65} redraw={true}>
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={(isVisible)=>handleVisibilityChange(isVisible)} delayedCall>
                    <span ref={countUpRef} />
                  </VisibilitySensor>
                )}
              </CountUp>
            }
            header="Personalised in flow of work"
            des="In MS Teams and Slack"
            // icon={growthIcon}
          />
          <HelpCard
            value={
              <CountUp suffix="x" end={3.5} redraw={true} decimals={1}>
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={(isVisible)=>handleVisibilityChange(isVisible)} delayedCall>
                    <span ref={countUpRef} />
                  </VisibilitySensor>
                )}
              </CountUp>
            }
            header="Faster goal attainment with AI"
            des="Hybrid coaching model that enhances the depth, personalization, and impact of traditional coaching."
            // icon={roiIcon}
          />
          <HelpCard
            value={
              <CountUp suffix="x" end={3.5} redraw={true} decimals={1}>
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={(isVisible)=>handleVisibilityChange(isVisible)} delayedCall>
                    <span ref={countUpRef} />
                  </VisibilitySensor>
                )}
              </CountUp>
            }
            header="ROI reported"
            des="After crunching HR data with aggregated coaching data to get the true ROI on coaching program"
            // icon={roiIcon}
          />
        </div>
      </div>
    </div>
  );
};

export default Outcome;
