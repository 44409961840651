import React, { useState } from "react";
// @ts-ignore
import i1Img from "../assets/img/tools.png";

// @ts-ignore
import slideImg1 from "../assets/img/workspace/workspace-slide-1.svg";
// @ts-ignore
import slideImg2 from "../assets/img/workspace/workspace-slide-2.svg";
// @ts-ignore
import slideImg3 from "../assets/img/workspace/workspace-slide-3.svg";
// @ts-ignore
import slideImg4 from "../assets/img/workspace/workspace-slide-4.svg";
// @ts-ignore
import arrowLeft from "../assets/img/arrow-left.png";
// @ts-ignore
import arrowRight from "../assets/img/arrow-right.png";
import VisibilitySensor from "react-visibility-sensor";
import useCarousel from "../hooks/useCarousel";

const slides = [
  {
    heading: "One click deployment",
    description:
      "Deploy Coachello in just one click, taking less than a minute. No additional log-ins or downloads required, making it effortlessly accessible for everyone in your company.",
    image: slideImg1,
  },
  {
    heading: "Real-time analytics",
    description:
      "Gain insights in real-time on vital data: utilization, performance, behavioral changes, and coaching topics.",
    image: slideImg2,
  },
  {
    heading: "Bank grade data security",
    description:
      "Coachello is SSL, M365 and ISO 27001:2013 certified. We are RGPD compliant. Our servers are hosted in France. We therefore guarantee total confidentiality and security of your data.",
    image: slideImg3,
  },
  {
    heading: "Higher adoption",
    description:
      "Integrated directly into your workspace, Coachello boosts program adoption by over 50%, ensuring easy access and a safe space for personal development.",
    image: slideImg4,
  },
];

const Workspace = () => {
  const {
    currentIndex,
    startAutoPlay,
    pauseAutoPlay,
    goToNextSlide,
    goToPreviousSlide,
  } = useCarousel({
    totalSlides: slides.length,
    autoPlayInterval: 10000,
  });

  const handleOnVisible = (isVisible: boolean) => {
    if (isVisible) {
      startAutoPlay();
    } else {
      pauseAutoPlay();
    }
  };

  return (
    <div className="bg-white section">
      <div className="flex flex-col items-center">
        <h2 className="mb-5 text-center">
          The untapped potential of having <br /> coaching in the workplace
        </h2>
        <img className="h-8 px-5" src={i1Img} />
      </div>

      <div className="flex flex-col md:flex-row">
        <div className="relative flex-1 p-12">
          <img
            onClick={goToPreviousSlide}
            className="absolute left-0 w-8 h-8 my-auto cursor-pointer top-1/2"
            src={arrowLeft}
          />
          <img
            onClick={goToNextSlide}
            className="absolute right-0 w-8 h-8 my-auto cursor-pointer top-1/2"
            src={arrowRight}
          />

          <div className="flex overflow-hidden ">
            <div
              className="flex transition-transform duration-500 "
              style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
              {slides.map((slide, index) => (
                <div
                  key={index}
                  className="w-full"
                  style={{ flex: `0 0 100%` }}
                >
                  <img src={slide.image} alt="Content" className="w-full" />
                </div>
              ))}
            </div>
          </div>
        </div>
        <VisibilitySensor onChange={handleOnVisible} delayedCall>
          <div className="flex flex-col items-start justify-center flex-1 p-8">
            <h4 className="mb-5">{slides[currentIndex].heading}</h4>
            <p>{slides[currentIndex].description}</p>
          </div>
        </VisibilitySensor>
      </div>
    </div>
  );
};

export default Workspace;
