import React, { useState } from "react";

// @ts-ignore
import backImg from "../assets/img/go-further/background.png";
// @ts-ignore
import peopleImg from "../assets/img/go-further/people-data.png";
// @ts-ignore
import businessImg from "../assets/img/go-further/business-data.png";
// @ts-ignore
import arrowImg from "../assets/img/go-further/down-arrow.png";
// @ts-ignore
import increaseIcon from "../assets/img/go-further/increase-icon.svg";

const GoFurther = () => {
  return (
    <div className="section lg:px-32 bg-white">
      <div className="flex flex-col items-center">
        <div className="text-center my-5 pb-7">
          <h2 className="">Go Further</h2>
          <p className="">Get true ROI by mixing people and business data</p>
        </div>

        <div
          style={{ backgroundImage: `url(${backImg})` }}
          className="flex flex-col items-center bg-contain w-full md:px-28 bg-no-repeat bg-top"
        >
          <div className="flex gap-5 justify-evenly mt-10 flex-col md:flex-row">
            <div className="flex basis-1 flex flex-col items-center gap-4">
              <h4>People data</h4>
              <img className="flex max-sm:w-2/3 md:h-5 mt-5" src={peopleImg} />
            </div>
            <img className="flex basis-auto h-32 hidden md:block" src={arrowImg} />
            <div className="flex basis-1 flex flex-col items-center gap-4">
              <h4>Business data</h4>
              <img className="flex max-sm:w-2/3 md:h-5 mt-5" src={businessImg} />
            </div>
          </div>
          <div className="max-sm:mt-8 mt-5 flex flex-col items-center w-full sm:w-2/3">
            <h5 className="text-center">
              True business Insights that help your
              <br className="m-0" />
              decisions of tomorrow:
            </h5>
            <br />
            <div className="bg-white w-full rounded border border-gray-400/30">
              <tr className="flex items-center justify-between border-b border-gray-400/30 px-3 py-2">
                Retention
                <span className="flex items-center gap-2 font-semibold">
                  24%
                  <img className="h-5" src={increaseIcon} />
                </span>
              </tr>
              <tr className="flex items-center justify-between border-b border-gray-400/30 px-3 py-2">
                Performance
                <span className="flex items-center gap-2 font-semibold">
                  40%
                  <img className="h-5" src={increaseIcon} />
                </span>
              </tr>
              <tr className="flex items-center justify-between border-b border-gray-400/30 px-3 py-2">
                Sales
                <span className="flex items-center gap-2 font-semibold">
                  15%
                  <img className="h-5" src={increaseIcon} />
                </span>
              </tr>
              <tr className="flex items-center justify-between border-b border-gray-400/30 px-3 py-2">
                In-group career transitions
                <span className="flex items-center gap-2 font-semibold">
                  80%
                  <img className="h-5" src={increaseIcon} />
                </span>
              </tr>
              <tr className="flex items-center justify-between border-b border-gray-400/30 px-3 py-2">
                Most chosen growth objectives
                <span className="flex items-center gap-2 font-semibold">
                  24%
                  <img className="h-5" src={increaseIcon} />
                </span>
              </tr>
              <tr className="flex items-center justify-between border-b border-gray-400/30 px-3 py-2">
                Identified behavioural change
                <span className="flex items-center gap-2 font-semibold">
                  Etc.
                  {/*<img className="h-5" src={increaseIcon} />*/}
                </span>
              </tr>
            </div>
          </div>
        </div>
        <div className="w-full md:w-[560px] rounded-md"></div>
      </div>
    </div>
  );
};

export default GoFurther;
