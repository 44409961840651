import React, { useState } from "react";
// @ts-ignore
import slide1Img from "../assets/img/howItWorks/slide1.png";
// @ts-ignore
import slide2Img from "../assets/img/howItWorks/slide2.png";
// @ts-ignore
import slide3Img from "../assets/img/howItWorks/slide3.png";
// @ts-ignore
import slide4Img from "../assets/img/howItWorks/slide4.png";
import useCarousel from "../hooks/useCarousel";
// @ts-ignore
import arrowLeft from "../assets/img/arrow-left.png";
// @ts-ignore
import arrowRight from "../assets/img/arrow-right.png";
import VisibilitySensor from "react-visibility-sensor";

const HowItWorks = () => {
  const slides = [
    {
      number: "01",
      title: "Assess strengths & growth opportunities",
      description:
        "Each program starts with the self-assessment of one’s strengths and growth opportunities in the Coachello MS Teams or Slack app.",
      imageUrl: slide1Img,
    },
    {
      number: "02",
      title: "Set individual goals",
      description: "Based upon the assessment results each participants sets their individual goals.",
      imageUrl: slide2Img,
    },
    {
      number: "03",
      title: "AI Powered Matching",
      description:
        "Coachello’s AI will then suggest two coaches for their coaching program (that has a stunning 99.8% matching success). Alternatively, a coachee can always book an orientation call with a Coachello lead coach.",
      imageUrl: slide3Img,
    },
    {
      number: "04",
      title: "Personalised follow-up",
      description: "In between coaching sessions Coachello will follow up with personalised reminders that are adapted to each individuals goals to increase the effectiveness of their individual transformation, and therefore the organisational transformation.",
      imageUrl: slide4Img,
    },
    // Add more slides as needed
  ];

  const {
    currentIndex,
    startAutoPlay,
    pauseAutoPlay,
    goToNextSlide,
    goToPreviousSlide,
  } = useCarousel({
    totalSlides: slides.length,
    autoPlayInterval: 10000,
  });
  const handleOnVisible = (isVisible: boolean) => {
    if (isVisible) {
      startAutoPlay();
    } else {
      pauseAutoPlay();
    }
  };
  return (
    <div className="bg-white section">
      <h2 className="text-center">How it works</h2>
      <div className="flex flex-col md:flex-row">
        <VisibilitySensor onChange={handleOnVisible} delayedCall>
          <div className="flex-1 p-8">
            <div className="flex flex-col justify-center md:mt-16">
              <h2 className="w-16 h-16 p-2 mb-2 mr-auto font-bold rounded-full bg-green">
                {slides[currentIndex].number}.
              </h2>
              <h3 className="mb-2 text-3xl font-semibold">
                {slides[currentIndex].title}
              </h3>
              <p className="text-lg text-gray-600">
                {slides[currentIndex].description}
              </p>
            </div>
          </div>
        </VisibilitySensor>
        <div className="relative flex-1 p-12">
          <img
            onClick={goToPreviousSlide}
            className="absolute left-0 w-8 h-8 my-auto cursor-pointer top-1/2"
            src={arrowLeft}
          />
          <img
            onClick={goToNextSlide}
            className="absolute right-0 w-8 h-8 my-auto cursor-pointer top-1/2"
            src={arrowRight}
          />

          <div className="flex overflow-hidden ">
            <div
              className="flex transition-transform duration-500 "
              style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
              {slides.map((slide, index) => (
                <div
                  key={index}
                  className="w-full"
                  style={{ flex: `0 0 100%` }}
                >
                  <img src={slide.imageUrl} alt="Content" className="w-full" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
